import { PopUp } from './PopUp'
import { Options } from './types'

export class MarketingApi {
  options: Options
  popup: PopUp
  form?: HTMLFormElement
  constructor(options: Options) {
    const defaultHandler = () => true // means process default redirect action
    this.options = Object.freeze(
      Object.assign(options, {
        onError: options.onError || defaultHandler,
        onSuccess: options.onSuccess || defaultHandler,
        onClose: options.onClose || defaultHandler,
        onRouting: options.onRouting || defaultHandler,
        onDisqualified: options.onDisqualified || defaultHandler,
      })
    )
    this.popup = new PopUp(this, options.lead || {}, options.domElement, options.event)
    this.form = options.form
  }

  setForm(form?: HTMLFormElement) {
    this.form = form
  }

  get formId() {
    return this.options.formId as string | number
  }

  get formOptions() {
    return {
      by: this.options.by,
      formIds: this.options.formIds,
      formId: this.options.formId,
      debug: this.options.debug === true,
      trigger: this.options.trigger || 'ThirdPartyForm',
      map: this.options.map === undefined ? true : this.options.map,
      domain: this.options.domain,
      router: this.options.router,
      locale: this.options.locale,
      ownerId: this.options.ownerId,
      accountId: this.options.accountId,
      type: this.options.type,
      skipOwnershipLogic: this.options.skipOwnershipLogic,
      webHook: this.options.webHook,
      disableRelation: this.options.disableRelation,
      skipFormFallbackUponAccountDQ: this.options.skipFormFallbackUponAccountDQ,
    }
  }

  showCalendar(data: Record<string, any>, domElement?: HTMLElement) {
    // if the data hasn't changed, we don't need to update data on popup
    // the url has already been loaded, just need to change width and height
    const shouldPostMessage = Object.keys(this.popup.data || {}).length === 0
    this.popup.setData(data, domElement)
    this.popup.showPopUp(shouldPostMessage)
  }

  onError(err: any) {
    let errorText = 'An error has occured'
    if (err) {
      errorText = err.responseText || err.response || err
    }
    console.error('chilipiper >> ', errorText)

    const errorInfo = {
      message: errorText,
      error: err,
    }

    if (this.options.onError) {
      this.options.onError(errorInfo)
    }
  }

  onSuccess(data: Record<string, any>) {
    if (this.options.onSuccess) {
      this.options.onSuccess(data)
    }
  }

  onClose(hasBooked: boolean) {
    if (this.options.onClose && !hasBooked) {
      this.options.onClose()
    }
  }

  reduceForm(form?: HTMLFormElement) {
    if (form) {
      const mapped = Array.from(form.elements)
        .filter(
          (element: any) =>
            (element.type !== 'radio' || (element.type === 'radio' && element.checked === true)) &&
            element.type !== 'password'
        )
        .filter((element: any) => element.name && element.name !== 'password')
        .reduce((acc: Record<string, string>, element: any) => {
          const name = element.name?.includes('.') ? element.name.split('.').join('') : element.name
          if (element.type === 'checkbox') {
            if (element.checked) {
              acc[name] = element.value
            }
          } else {
            acc[name] = element.value
          }
          return acc
        }, {})
      return mapped
    }
    return {}
  }
}
