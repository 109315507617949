import { Optional, Options } from './types'

export function waitUntilElementExists(
  selector: string,
  callback: (elements: NodeListOf<HTMLFormElement>) => any,
  bypass = false
) {
  const el = document.querySelectorAll<HTMLFormElement>(selector)
  if (el.length) {
    // eslint-disable-next-line no-console
    console.debug(`form found: ${selector}`)
    return callback(el)
  } else if (bypass) {
    return callback(el)
  }

  setTimeout(() => {
    waitUntilElementExists(selector, callback)
  }, 500)
}

export function findForms(options: Optional<Options, 'form'>) {
  if (options.query) {
    return options.query
  }

  if (options.formIds) {
    return options.formIds.map(id => `#${id}`).join(',')
  }

  if (options.formId) {
    return `#${options.formId}`
  }
  return 'form'
}

export const executeWhenFormLoads = (
  handler: (options: Optional<Options, 'form'>, forms: NodeListOf<HTMLFormElement>) => void
) => {
  return (options: Optional<Options, 'form'>) => {
    return waitUntilElementExists(
      findForms(options),
      forms => handler({ ...options, shouldCheck: true }, forms),
      !!options.lead || !!options.form
    )
  }
}
