import 'whatwg-fetch'
import { initSentryClient, captureException } from '../sentryClient'
import { MarketingApi } from './MarketingApi'
import { executeWhenFormLoads } from './utils'
import { MarketoForm } from './forms/MarketoForm'
import { PardotForm } from './forms/PardotForm'
import { HtmlForm } from './forms/HtmlForm'
import { HubspotForm } from './forms/HubspotForm'
import { HubSpotEvent, HubspotFormFields, Optional, Options } from './types'
import { deployRouter } from './snippets'

// eslint-disable-next-line no-console
console.log('Concierge version', process.env.APP_VERSION)

initSentryClient()

const createIntegration = (form: HTMLFormElement, options: Optional<Options, 'form'>) => {
  const formId = form.id ?? ''
  //@ts-ignore TODO: add reason
  const isHubspotForm = typeof hbspt !== 'undefined' && formId.includes('hsForm')
  const isMarketo = formId.includes('mktoForm') && window.MktoForms2 !== undefined
  const isPardot = formId === 'pardot-form' || formId.includes('gform')
  if (isHubspotForm) {
    return new HubspotForm({ ...options, form: form })
  }
  if (isMarketo) {
    return new MarketoForm({ form, formId, ...options })
  }
  if (isPardot) {
    return new PardotForm({ form, ...options })
  }
  return new HtmlForm({ form, ...options })
}

const integrateForms = (options: Optional<Options, 'form'>, forms: NodeListOf<HTMLFormElement>) => {
  const integration = Array.from(forms).map(form => createIntegration(form, options))
  return integration
}

const showCalendarHubspotMobile = (options: Options) => {
  const hubspotData = JSON.parse(window.localStorage.getItem('hubspotData') || '')
  const hubspotForm = new HubspotForm({ ...options })
  hubspotForm.showCalendar(hubspotData, options.domElement)
}

let IntegrationsRetry = 20
export const bookMeeting = (
  options: Optional<Options, 'form'>,
  forms: NodeListOf<HTMLFormElement>
) => {
  if (forms.length > 0) {
    integrateForms(options, forms)
  } else if (
    window.location.href.includes('hsFormGuid') &&
    window.location.href.includes('submissionGuid')
  ) {
    showCalendarHubspotMobile(options as Options)
  } else if (IntegrationsRetry > 0) {
    IntegrationsRetry -= 1
    setTimeout(() => {
      bookMeeting(options, forms)
    }, 1000)
  } else {
    // eslint-disable-next-line no-console
    console.log('chilipiper >> could not integrate [form absent]')
  }
}

const showCalendarAfterCheck =
  (marketingApi: MarketingApi, formData: Record<string, string>) =>
  (options: Optional<Options, 'form'>) => {
    marketingApi.showCalendar(formData, options.domElement)
  }

export const submitAndRoute = (
  options: Optional<Options, 'form'>,
  marketingApi: MarketingApi,
  forms?: NodeListOf<HTMLFormElement>
) => {
  try {
    if (options.debug) {
      // eslint-disable-next-line no-console
      console.log('chilipiper >> ', options)
    }
    const form = options.form || (forms ? forms[0] : undefined)
    marketingApi.setForm(form)
    if (options.lead) {
      options.map = options.map || false
      marketingApi.showCalendar(options.lead, options.domElement)
    } else {
      options.map = true
      const formData = Array.from(form?.elements ?? []).reduce((acc: Record<string, string>, x) => {
        acc[(x as HTMLInputElement).name] = (x as HTMLInputElement).value
        return acc
      }, {})
      // for form on page https://verse.io/schedule-a-demo/ the field values are empty if check is done before mapping
      showCalendarAfterCheck(marketingApi, formData)(options)
    }
  } catch (err) {
    captureException(err)
  }
}

export const showCalendar = (
  options: Optional<Options, 'form'>,
  forms?: NodeListOf<HTMLFormElement>
) => {
  const dataPardot = JSON.parse(window.localStorage.getItem('dataPardot') || '{}')
  const form = options.form || (forms ? forms[0] : undefined)
  const marketingApi = new MarketingApi({ form, ...options })
  marketingApi.showCalendar(dataPardot, options.domElement)
}

const initHubspotIframeForm = (
  options: Optional<Options, 'form'>,
  forms?: NodeListOf<HTMLFormElement>
) => {
  const formIds = options.formIds ?? []
  const marketingApi = new MarketingApi(options)
  window.addEventListener('message', (event: HubSpotEvent) => {
    if (formIds.length > 0 && !formIds.includes(event.data.id)) {
      return
    }
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      const submissionValues = (event.data.data as HubspotFormFields).submissionValues || {}
      const lead = Object.keys(submissionValues).reduce((acc, key) => {
        if (Array.isArray(submissionValues[key])) {
          acc[key] = submissionValues[key].toString().replaceAll(',', ';')
        } else {
          acc[key] = submissionValues[key]
        }
        return acc
      }, {} as Record<string, string>)
      // eslint-disable-next-line no-console
      console.log(lead)
      submitAndRoute(
        {
          ...options,
          map: true,
          lead: lead,
        },
        marketingApi,
        forms
      )
    }
  })
}

const getQueryVariables = () => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  const varObject: Record<string, string> = {}
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    varObject[pair[0]] = decodeURIComponent(pair[1])
  }
  return varObject
}

const getQueryVariable = (variable: string) => {
  const variables = getQueryVariables()

  return variables[variable] || false
}

const queryVariableIncludes = (value: string) => {
  const variables = getQueryVariables()

  return Object.keys(variables).some(key => key.toLowerCase().includes(value.toLowerCase()))
}

export const getQuery = (options: Optional<Options, 'form'>) => {
  const checkBeforeRouting = options.checkInclusive ? queryVariableIncludes : getQueryVariable

  if (checkBeforeRouting(options.queryVariable || 'email')) {
    const newOptions = {
      ...options,
      map: options.map !== undefined ? options.map : true,
      lead: {
        ...(options.lead || {}),
        ...getQueryVariables(),
      },
    } as Optional<Options, 'form'>
    const marketingApi = new MarketingApi(newOptions)
    submitAndRoute({ ...newOptions }, marketingApi)
  }
}

export const ChiliPiper = {
  getQuery: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => getQuery({ domain, router, ...options }),
  scheduling: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => executeWhenFormLoads(bookMeeting)({ domain, router, ...options }),
  submit: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) =>
    executeWhenFormLoads((options, forms) => {
      return submitAndRoute({ ...options }, new MarketingApi(options), forms)
    })({ domain, router, ...options }),
  initHSIframeForm: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => initHubspotIframeForm({ domain, router, ...options }),
  deploy: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => deployRouter({ domain, router, ...options }),
}

if (window.ChiliPiper) {
  Object.keys(window.ChiliPiper).forEach(key => {
    //@ts-ignore TODO: add reason
    if (window.ChiliPiper[key].cp?.length) {
      //@ts-ignore TODO: add reason
      window.ChiliPiper[key].cp.forEach(param => ChiliPiper[key](...param))
    }
    //@ts-ignore For the sake of backwards compatibility
    if (window.ChiliPiper[key].q?.length) {
      //@ts-ignore For the sake of backwards compatibility
      window.ChiliPiper[key].q.forEach(param => ChiliPiper[key](...param))
    }
  })
}

;(global as any).ChiliPiper = ChiliPiper
